function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

function adUnits() {

    let units = document.getElementsByClassName("ad-unit");
    const adUnitStyles = document.createElement("style");
    document.head.appendChild(adUnitStyles);

    this.scaleUnits = debounce(function () {
        adUnitStyles.innerText = "";
        for (let i = units.length - 1; i >= 0; i--) {

            let adWidth = units[i].dataset.width,
                scale = Math.min(1, (units[i].parentElement.offsetWidth) / adWidth);

            let scaleStyles =
                `\n
                    #${units[i].querySelector("div:first-of-type").id} iframe {\n
                    -webkit-transform: scale(${scale});\n
                    -moz-transform: scale(${scale});\n
                    -ms-transform: scale(${scale});\n
                    -o-transform: scale(${scale});\n
                    transform-origin: 0 50%;\n
                    }
                \n`
            adUnitStyles.innerText += scaleStyles;
        }
    }, 250);

    this.watchResize = function () {
        window.addEventListener('resize', this.scaleUnits);
    }

}

export default adUnits;