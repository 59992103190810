class Tabs {

    constructor(element) {
        this.element = element;
        this.triggers = element.querySelectorAll(".js-tab a");
        this._init();
    }

    _init() {
        let _this = this;
        let tabContent = _this.element.querySelectorAll(".js-tab-content");
        for (let i = tabContent.length - 1; i >= 0; i--) {
            if (!tabContent[i].classList.contains("js-active-tab")) {
                tabContent[i].classList.add("js-hidden-tab");
            }
        }

        let triggers = _this.triggers;
        for (let i = triggers.length - 1; i >= 0; i--) {
            triggers[i].addEventListener("click", function (event) {
                event.preventDefault();
                if (!this.parentNode.classList.contains("is-active")) {
                    _this._changeTab(this);
                }
            });
        }
    }

    _changeTab(element) {
        // remove active classes
        let _this = this;
        let activeTrigger = _this.element.querySelector(".js-tab.is-active");
        let activeTab = _this.element.querySelector(".js-tab-content.js-active-tab");
        let targetId = element.getAttribute("href").substr(1);
        let newActiveTab = _this.element.querySelector(`#${CSS.escape(targetId)}`);
        if (activeTrigger) {
            activeTrigger.classList.remove("is-active");
            activeTab.classList.remove("js-active-tab");
            activeTab.classList.add("js-hidden-tab");
        }

        // add new active classes
        element.parentNode.classList.add("is-active");
        newActiveTab.classList.add("js-active-tab");
    }

}

export default Tabs;
