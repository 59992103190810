import tippy from 'tippy.js';

class Tooltips {
    constructor() {
        this.init();
    }

    init() {
        document.querySelectorAll('.tippy').forEach(btn => {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
            });
            tippy(btn, {
                content(reference) {
                    const id = reference.getAttribute('data-template');
                    const template = document.getElementById(id);
                    return template.innerHTML;
                },
                allowHTML: true,
                interactive: true,
                animation: 'fade',
                theme: 'dcd',
                maxWidth: 'none',
                arrow: true,
                appendTo: document.body,
                popperOptions: {
                    modifiers: {
                        flip: { enabled: false },
                    }
                }
            })
        });
    }
}

export default Tooltips;
