function carousel(selector) {
    if (selector.charAt(0) == '.') {
        selector = selector.substr(1);
    }
    let carousel = document.getElementsByClassName(selector)[0],
        carouselTriggers = carousel.getElementsByClassName('carousel__trigger'),
        carouselSlides = carousel.getElementsByClassName('carousel__item');

    for (let i = carouselTriggers.length - 1; i >= 0; i--) {
        carouselTriggers[i].addEventListener('click', function (e) {
            // the link on the active item should behave as normal
            if (!this.classList.contains('active')) {
                e.preventDefault();
                changeSlide(this);
            }
        });
    };

    function changeSlide(trigger) {
        // remove old "active" classes
        carousel.getElementsByClassName('carousel__trigger active')[0].classList.remove('active');
        carousel.getElementsByClassName('carousel__item active')[0].classList.remove('active');
        // add new "active" classes
        trigger.classList.add('active');
        document.getElementById(trigger.dataset.target).classList.add('active');
    }

    function getNextSlideTrigger() {
        let nextSlideNumber,
            currentSlideId = document.getElementsByClassName('carousel__item active')[0].getAttribute('id'),
            currentSlideNumber = currentSlideId.replace(/^\D+/g, ''),
            slideCount = carouselSlides.length;

        if (currentSlideNumber == slideCount) {
            nextSlideNumber = 1;
        }
        else {
            nextSlideNumber = (parseInt(currentSlideNumber) + 1);
        }

        return carouselTriggers[nextSlideNumber - 1];
    }

    this.cycle = function () {
        let intervalID;
        const startCycle = () => {
            intervalID = window.setInterval(function () {
                let next = getNextSlideTrigger();
                changeSlide(next);
            }, 5000);
        };
        startCycle();

        carousel.addEventListener('mouseover', function () {
            clearTimeout(intervalID);
        });

        carousel.addEventListener('mouseout', function () {
            startCycle();
        });
    }
}

export default carousel;
