class Slider {

    constructor(element) {
        this.element = element;
        this.slides = Array.from(element.querySelectorAll(".js-slider-slide"));
        this.itemsToSlide = element.getAttribute("data-items-to-slide"); // build this out later
        this.hideControls = false;
        this.autoPlay = false;
        this._init();
    }

    _init() {
        let _this = this;

        _this.slides.forEach(function (value, i) {
            if (i > 0) {
                value.classList.add("js-slider-hidden");
            }
            else {
                value.classList.add("js-slider-active");
            }
        });

        // options
        if (_this.element.getAttribute("data-auto-play")) {
            _this.autoPlay = true;
        }
        if (_this.element.getAttribute("data-hide-controls")) {
            _this.hideControls = true;
        }

        if (_this.hideControls === false) {
            _this._buildControls();
        }

        if (_this.autoPlay === true) {
            _this._autoPlay();
        }
    }

    _buildControls() {
        let _this = this;

        let controls = document.createElement("div");
        controls.classList.add("js-slider-controls");
        _this.element.appendChild(controls);
        let prevButton = document.createElement("button");
        prevButton.classList.add("slider__button");
        let prevIcon = document.createElement("i");
        prevIcon.classList.add("far", "fa-angle-left");
        prevButton.appendChild(prevIcon);
        let nextButton = document.createElement("button");
        nextButton.classList.add("slider__button");
        let nextIcon = document.createElement("i");
        nextIcon.classList.add("far", "fa-angle-right");
        nextButton.appendChild(nextIcon);
        controls.appendChild(prevButton);
        controls.appendChild(nextButton);

        prevButton.addEventListener("click", function () {
            _this._previousSlide();
        });
        nextButton.addEventListener("click", function () {
            _this._nextSlide();
        });
    }

    _nextSlide() {
        let _this = this;
        let nextIndex = function (array, index) {
            if (index >= 0 && index < array.length - 1) {
                index++;
            }
            else {
                index = 0;
            }
            return index;
        }

        let activeSlide = _this.element.querySelector(".js-slider-active");
        let currentActiveIndex = _this.slides.indexOf(activeSlide);
        let nextSlide = _this.slides[nextIndex(_this.slides, currentActiveIndex)];

        activeSlide.classList.remove("js-slider-next-in", "js-slider-prev-in");

        activeSlide.classList.add("js-slider-hidden");
        activeSlide.classList.remove("js-slider-active");
        nextSlide.classList.add("js-slider-active", "js-slider-next-in");
        nextSlide.classList.remove("js-slider-hidden");

    }

    _previousSlide() {
        let _this = this;
        let previousIndex = function (array, index) {
            if (index >= 1) {
                index--;
            }
            else {
                index = array.length - 1;
            }
            return index;
        }

        let activeSlide = _this.element.querySelector(".js-slider-active");
        let currentActiveIndex = _this.slides.indexOf(activeSlide);
        let nextSlide = _this.slides[previousIndex(_this.slides, currentActiveIndex)];

        activeSlide.classList.remove("js-slider-next-in", "js-slider-prev-in");

        activeSlide.classList.add("js-slider-hidden");
        activeSlide.classList.remove("js-slider-active");
        nextSlide.classList.remove("js-slider-hidden");
        nextSlide.classList.add("js-slider-active", "js-slider-prev-in");
    }

    _autoPlay() {
        let _this = this;

        let intervalID;
        const startCycle = () => {
            intervalID = window.setInterval(function () {
                _this._nextSlide();
            }, 5000);
        };

        startCycle();

        _this.element.addEventListener('mouseover', function () {
            clearTimeout(intervalID);
        });

        _this.element.addEventListener('mouseout', function () {
            startCycle();
        });
    }

}

export default Slider;
