class TrendingStories {
    constructor() {
        this.init();
    }

    init() {
        const trendingStoriesEl = document.querySelectorAll(
            "[data-trending-stories]"
        );
        trendingStoriesEl.forEach(el => {
            const parentPagePk = el.dataset.trendingStories;
            fetch(`/trending-stories/?pk=${parentPagePk}`)
                .then(res => res.text())
                .then(text => {
                    el.insertAdjacentHTML("beforeend", text);
                });
        });
    }
}

export default TrendingStories;
