class Lightbox {
    constructor() {
        const opened = () => document.documentElement.style.overflow = 'hidden';
        const closed = () => document.documentElement.style.overflow = 'auto';
        const getCaption = (trigger) => {
            let caption = trigger.parentElement.querySelector('.js-lightbox-caption');
            return caption && caption.innerHTML;
        }

        const options = {
            caption: getCaption,
            onOpen: opened,
            onClose: closed,
            injectBaseStyles: false,
        }

        const lightboxTriggers = document.querySelectorAll('.js-lightbox-trigger');

        new LuminousGallery(lightboxTriggers, options);
    }
}

export default Lightbox;