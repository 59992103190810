class Filter {

    constructor(element) {
        this.element = element;
        this.filterSelects = element.querySelectorAll(".js-filter-select");
        this.filterReset = element.querySelector(".js-filter-reset");
        this.grouper = this.element.getAttribute("data-filter-grouper");
        this.initialFilterContent = element.querySelector(".js-filter-content");
        this.activeFilters = [];
        this._init();
    }

    _init() {
        let _this = this;
        _this.filterReset.addEventListener("click", function (event) {
            event.preventDefault();
            _this._resetFilter();
        });

        for (let i = _this.filterSelects.length - 1; i >= 0; i--) {
            _this.filterSelects[i].addEventListener("click", function (event) {
                event.preventDefault();
                _this._updateFilter(_this.filterSelects[i], _this.activeFilters);
            });
        }

    }

    _updateFilter(element, activeFilters) {
        let _this = this;

        if (_this.activeFilters.includes(element.getAttribute("data-filter-key"))) {
            _this.activeFilters = _this.activeFilters.filter(item => item !== element.getAttribute("data-filter-key"));
        }
        else {
            _this.activeFilters.push(element.getAttribute("data-filter-key"));
        }

        _this._applyFilter();
    }

    _resetFilter() {
        let _this = this;
        _this.activeFilters = [];
        _this._applyFilter();
    }

    _applyFilter() {
        let _this = this;

        if (_this.activeFilters.length === 0) {
            _this.filterReset.classList.add("is-active");
        }
        else {
            _this.filterReset.classList.remove("is-active");
        }

        for (let i = _this.filterSelects.length - 1; i >= 0; i--) {
            _this.filterSelects[i].classList.remove("is-active");
            if (_this.activeFilters.includes(_this.filterSelects[i].getAttribute("data-filter-key"))) {
                _this.filterSelects[i].classList.add("is-active");
            }
        }

        let initialFilterContent = _this.initialFilterContent.cloneNode(true);
        if (_this.activeFilters.length > 0) {
            let toFilter = initialFilterContent.querySelectorAll(".js-filter-item");
            for (var i = toFilter.length - 1; i >= 0; i--) {
                let filterItems = toFilter[i].getAttribute("data-filter-item").split(",");
                if (!_this.activeFilters.some(r => filterItems.includes(r))) {
                    if (_this.grouper !== "null" && toFilter[i].parentNode.children.length === 1) {
                        toFilter[i].closest(_this.grouper).remove();
                    }
                    else {
                        toFilter[i].remove();
                    }
                }
            }
        }

        let filterParent = _this.element.querySelector(".js-filter-content").parentNode;
        while (filterParent.firstChild) filterParent.firstChild.remove();

        filterParent.appendChild(initialFilterContent);
    }
}

export default Filter;
