class BroadcastPoller {
    constructor() {
        this.element = document.querySelector("#stream_status");
        this.maxTries = 240;
        this.interval = 30000;
        this.init();
    }

    init() {
        if (!this.element) {
            return;
        }
        const streamPageId = this.element.dataset.streamPageId;
        const startDate = new Date(this.element.dataset.startDate);
        const endDate = new Date(this.element.dataset.endDate);
        const today = new Date();

        if (today >= startDate && today <= endDate) {
            this.checkStatus(streamPageId);;
        }
        else {
            return;
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async checkStatus(streamPageId, tries = 0) {
        let responseObj;
        const url = "/events/stream-status/" + streamPageId;

        if (tries == this.maxTries) {
            return;
        }

        try {
            const response = await fetch(url);
            responseObj = await response.json();
            if (responseObj.status !== "PS") {
                location.reload();
            }
            else {
                tries++;
                await this.sleep(this.interval);
                this.checkStatus(streamPageId, tries);
            }
        }
        catch (error) {
            return;
        }
    }
}

export default BroadcastPoller;