class Accordion {
    constructor(element) {
        this.element = element;
        this.triggers = element.querySelectorAll(".js-accordion-title");
        this._init()
    }

    _init() {
        let _this = this;
        let triggers = _this.triggers;
        triggers.forEach(trigger => {
            trigger.addEventListener("click", function (event) {
                // find a target
                let target = trigger.closest(".js-accordion-item").querySelector(".js-accordion-body");

                target.setAttribute(
                    "data-hidden",
                    target.getAttribute("data-hidden") === "true"
                        ? "false"
                        : "true"
                );

                if (trigger.classList.contains("is-active")) {
                    trigger.classList.remove("is-active");
                } else {
                    trigger.classList.add("is-active");
                }
            });
        });
    }
}

export default Accordion;