class EqualHeight {
    constructor() {
        this.init()
    }

    init() {
        const elements = document.querySelectorAll(".js-eqh");
        let targetHeight = 0;

        elements.forEach(el => {
            if (el.offsetHeight > targetHeight) {
                targetHeight = el.offsetHeight;
            }
        })

        elements.forEach(el => {
            el.style.height = targetHeight + "px";
        });

        this.resize(elements);
    }

    resize(elements) {
        let height = 0;
        elements.forEach(function (element) {
            if (element.offsetHeight > height) {
                height = element.offsetHeight;
            }
        });
    }
}

export default EqualHeight;