class Gmaps {

    constructor(elements) {
        this.maps = Array.from(elements);
        this.apiKey = this.maps[0].getAttribute("data-gmap-api-key");
        this._init();
    }

    _init() {
        let _this = this;
        let head = document.getElementsByTagName("head")[0];
        let script = document.createElement("script");
        script.onload = function () {
            _this.maps.forEach((item) => {
                let base = new google.maps.LatLng(51.5235671, -0.0851172);
                let map = new google.maps.Map(item, {
                    center: base,
                    zoom: 15,
                    mapTypeControl: false,
                    streetViewControl: false
                });
                let coordinates = item.getAttribute("data-coordinates").split(",", 2);
                let latLng = coordinates.map(function (coord) {
                    return parseFloat(coord);
                });
                let position = { lat: latLng[0], lng: latLng[1] };
                let showOverlay = item.getAttribute("data-overlay");
                if (showOverlay == "true") {
                    map.panBy(-200, 0);
                }
                map.setCenter(position);

                let marker = new google.maps.Marker({
                    position: position,
                    map: map
                });
            });
        };

        script.type = "text/javascript";
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + _this.apiKey;
        head.appendChild(script);
    }

}

export default Gmaps;
