class NavigationMobile {
    constructor() {
        this.menu = document.querySelector("#navigation-mobile");
        this.toggle = document.querySelector("[data-navigation-mobile-toggle]");
        this.body = document.body;
        this.init();
    }

    showMenu() {
        this.menu.classList.add("is-active");
        this.toggle.classList.add("is-active");
        this.body.classList.add("is-mobile-menu-active");
    }

    hideMenu() {
        this.menu.classList.remove("is-active");
        this.toggle.classList.remove("is-active");
        this.body.classList.remove("is-mobile-menu-active");
    }

    init() {
        if (!this.menu) {
            return;
        }
        this.toggle.addEventListener("click", (event) => {
            if (this.menu.classList.contains("is-active")) {
                this.hideMenu();
            } else {
                this.showMenu();
            }
            event.preventDefault();
        });

        const items = this.menu.querySelectorAll("li");

        items.forEach((item) => {
            const link = item.querySelector("a,button");
            const childPage = item.querySelector("ul");

            if (childPage) {
                link.setAttribute("href", "#");
                link.addEventListener("click", (event) => {
                    childPage.classList.add("is-active");
                    event.preventDefault();
                });

                const upLink = childPage.querySelector("[data-action=up-menu]");
                upLink.addEventListener("click", (event) => {
                    childPage.classList.remove("is-active");
                    event.preventDefault();
                });
            }
        });
    }
}

export default NavigationMobile;
