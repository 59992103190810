class Announcement {
    constructor() {
        this.element = document.querySelector(".js-announcement");
        this.dismissBtn = document.querySelector(".js-announcement .js-announcement-dismiss");
        this.init();
    }

    init() {
        if (!this.element) {
            return;
        }
        const dismiss = this.dismissBtn;
        const announcementId = this.element.dataset.id;

        let dismissed = localStorage.getItem("dismissedAnnouncement");

        if (dismissed !== announcementId) {
            this.element.classList.remove("announcement--hidden");
        }

        dismiss.addEventListener("click", (event) => {
            event.preventDefault()
            localStorage.setItem("dismissedAnnouncement", announcementId);
            this.element.classList.add("announcement--hidden");
        });
    }
}

export default Announcement