require("./js/components/polyfills");
require("./css/main.scss");
require("cookieconsent");
require("cookieconsent/build/cookieconsent.min.css");
require("./vendor/luminous/Luminous.min.js");

import Narration from "./js/components/narration";
import Tooltips from "./js/components/tooltips";
import carousel from "./js/components/carousel";
import adUnits from "./js/components/adunits";
import priorityNav from "./js/components/priorityNav";
import slider from "./js/components/slider";
import marketoforms from "./js/components/marketoforms";
import lightbox from "./js/components/lightbox";
import Gallery from "./js/components/gallery";
import Tabs from "./js/components/tabs";
import Filter from "./js/components/filter";
import Gmaps from "./js/components/googlemaps";
import TrendingStories from "./js/components/trending-stories";
import NavigationMobile from "./js/components/navigation-mobile";
import Accordion from "./js/components/accordion";
import "htmx.org/dist/htmx.js";
import "./js/components/htmx-window";
import 'regenerator-runtime/runtime';
import BroadcastPoller from "./js/components/broadcastpoller";
import Announcement from "./js/components/announcement";
import EqualHeight from "./js/components/equal-height.js";


function ready(fn) {
    if (
        document.attachEvent
            ? document.readyState === "complete"
            : document.readyState !== "loading"
    ) {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

ready(() => {
    new TrendingStories();
    new Narration();
    new Tooltips();
    new NavigationMobile();
    new BroadcastPoller();
    new Announcement();
    new EqualHeight();
});

var carouselExists = document.getElementsByClassName("carousel");
if (carouselExists.length !== 0) {
    const c = carousel(".carousel");
    c.cycle();
}

window.cookieconsent.initialise({
    palette: {
        popup: {
            background: "#edeff5",
            text: "#838391",
        },
        button: {
            background: "#144e5a",
        },
    },
});

const au = new adUnits();
au.scaleUnits();
au.watchResize();

const priorityNavElements = document.querySelectorAll(".js-priority-menu");
if (priorityNavElements.length !== 0) {
    for (let i = priorityNavElements.length - 1; i >= 0; i--) {
        priorityNav(priorityNavElements[i]);
    }
}

let sliderElements = document.querySelectorAll(".js-slider");
if (sliderElements.length !== 0) {
    for (let i = sliderElements.length - 1; i >= 0; i--) {
        if (sliderElements[i].children.length > 1) {
            new slider(sliderElements[i]);
        }
    }
}

const mf = marketoforms();

const lightboxElements = document.querySelectorAll(".js-lightbox-item");
if (lightboxElements.length !== 0) {
    new lightbox();
}

const galleryElements = document.querySelectorAll(".js-gallery");
if (galleryElements.length !== 0) {
    for (let i = galleryElements.length - 1; i >= 0; i--) {
        new Gallery(galleryElements[i]);
    }
}

let tabsElements = document.querySelectorAll(".js-tab-group");
if (tabsElements.length !== 0) {
    for (let i = tabsElements.length - 1; i >= 0; i--) {
        ready(new Tabs(tabsElements[i]));
    }
}

let filterElements = document.querySelectorAll(".js-filter");
if (filterElements.length !== 0) {
    for (let i = filterElements.length - 1; i >= 0; i--) {
        ready(new Filter(filterElements[i]));
    }
}

let mapElements = document.querySelectorAll(".js-googlemap");
if (mapElements.length !== 0) {
    ready(new Gmaps(mapElements));
}

let accordionElements = document.querySelectorAll(".accordion");
if (accordionElements.length !== 0) {
    accordionElements.forEach(element => {
        ready(new Accordion(element));
    });
}

ready(
    htmx.on("htmx:load", function (evt) {
        try {
            let elt = evt.detail.elt;
            if (elt.dataset.authenticated == "true") {
                if ("marketoId" in elt.dataset) {
                    const marketo_id = elt.dataset.marketoId;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(
                        {
                            'event': 'logged_in_view',
                            'marketo_id': marketo_id,
                        }
                    );
                };
            }
            if (elt.dataset.authenticated == "false") {
                let displayForAnonymousContent = document.querySelectorAll(".js-display-for-anonymous");
                displayForAnonymousContent.forEach(element => {
                    element.classList.remove("js-display-for-anonymous");
                });
            }
        } catch (error) {
            // fail somewhat gracefully.
        }
    })
);
