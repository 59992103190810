function loadMarketoForms() {
    let forms = Array.from(document.querySelectorAll('.js-mktoFormBlock'));

    let removeInlineStyles = function (formEl) {
        // remove element styles from root and children
        for (let elsWithStyles = document.querySelectorAll("#" + formEl.id + ", #" + formEl.id + " [style]"), i = 0, imax = elsWithStyles.length; i < imax; i++) {
            elsWithStyles[i].removeAttribute("style");
        }
    };

    if (forms.length > 0) {
        let head = document.getElementsByTagName('head')[0],
            script = document.createElement('script'),
            body = document.getElementsByTagName('body')[0];

        script.type = 'text/javascript';
        script.onload = function () {
            forms.forEach((form) => {
                form.parentNode.style.visibility = "hidden";

                let formId = form.id.split('_')[1];
                let thankYouMsg = form.parentNode.getElementsByClassName('js-formThankYou')[0];
                let dimensions = {};
                try {
                    dimensions = JSON.parse(
                        document.getElementById("dimensions-data").textContent
                    );
                } catch (error) { }

                MktoForms2.loadForm("//app-lon03.marketo.com", settings.marketo_id, formId, function (mktoForm) {
                    mktoForm.onSuccess(function (values, followUpUrl) {
                        if (form.dataset.regform) {
                            try {
                                let webregscript = document.createElement("script");
                                webregscript.src = `//app.webreg.me/communities/${form.dataset.gleanintoken}/dashboard.js`;
                                let webregbuttons = document.getElementById("webreg_buttons");
                                webregbuttons.remove();

                                webRegMeClientData.emailAddress = values.Email;
                                webRegMeClientData.badgeNumber = values.checksum.substring(0, 7);
                                webRegMeClientData.firstName = values.FirstName;
                                webRegMeClientData.lastName = values.LastName;
                                webRegMeClientData.companyName = values.Company;
                                webRegMeClientData.jobTitle = values.Title;

                                body.parentNode.insertBefore(webregscript, body.nextSibling);
                            }
                            catch (error) {
                                // fail silently
                            }
                        }
                        if (form.dataset.gxregform) {
                            try {
                                let campaigns_widget = document.createElement("script"),
                                    converts = document.createElement("script");
                                campaigns_widget.src = "//app.gleanin.com/campaigns_widget.js";
                                converts.src = "//invt.io/s/d7ea8899ce/converts.js";
                                body.parentNode.insertBefore(converts, body.nextSibling);
                                body.parentNode.insertBefore(campaigns_widget, body.nextSibling);
                            }
                            catch (error) {
                                // fail silently
                            }
                        }

                        form.remove();
                        thankYouMsg.style.display = "flex";
                        thankYouMsg.style.flexDirection = "column";
                        try {
                            ga("send", "event", "Form", "Submitted", formId, dimensions);
                        }
                        catch (error) {
                            // fail silently
                        }
                        return false
                    });
                });

                MktoForms2.whenReady(function (mktoForm) {
                    let formEl = mktoForm.getFormElem()[0];

                    // remove style nodes
                    while (formEl.querySelector("style")) {
                        formEl.removeChild(formEl.querySelector("style"));
                    }

                    removeInlineStyles(formEl);

                    // disable all Marketo-sourced stylesheets
                    for (let styleSheets = document.styleSheets, i = 0, imax = styleSheets.length; i < imax; i++) {
                        let ssLoc = document.createElement("A");
                        ssLoc.href = styleSheets[i].href;

                        if ((ssLoc.hostname.search(/\.marketo\.com$/) !== -1) //  external STYLEs
                            ||
                            ((styleSheets[i].ownerNode || styleSheets[i].owningElement).parentNode == formEl)) { //  inline STYLEs within FORM tag
                            styleSheets[i].disabled = true;
                        }
                        // remove styles specified in "Custom CSS"
                        try {
                            if (styleSheets[i].rules[0].cssText.search(/mkto/) !== -1) {
                                styleSheets[i].disabled = true;
                            }
                        }
                        catch (error) {
                            // fail silently
                        }

                        // remove extraneous elements
                        let garbageElements = [...form.querySelectorAll(".mktoClear, .mktoOffset, .mktoGutter")];
                        garbageElements.forEach((item) => {
                            item.parentNode.removeChild(item);
                        });
                    }

                    form.parentNode.style.visibility = "visible";
                });

                MktoForms2.whenRendered(function (mktoForm) {
                    // remove inline styles when fields added via visibility rules
                    let formEl = mktoForm.getFormElem()[0];
                    removeInlineStyles(formEl);
                    try {
                        ga("send", "event", "Form", "Displayed", formId, dimensions);
                    }
                    catch (error) {
                        // fail silently
                    }
                });
            });
        }
        script.src = "//app-lon03.marketo.com/js/forms2/js/forms2.min.js";
        head.appendChild(script);
    }
}

export default loadMarketoForms;
