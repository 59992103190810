function priorityNav(element) {
    const priorityContainer = element;
    const priorityMenu = priorityContainer.querySelector(".priority");
    const overflowMenu = priorityContainer.querySelector(".overflow");
    const overflowTrigger = priorityContainer.querySelector(
        ".js-overflow-trigger"
    );
    const menuItems = priorityMenu.children;
    const delay = 300;
    let throttled = false;
    let breakout = [];
    let timeout = 0;

    const throttle = (callback, delay, scope) => {
        if (!throttled) {
            callback.call(scope);
            throttled = true;

            setTimeout(function () {
                throttled = false;
            }, delay);
        }
    };

    // Initialise
    const initialise = () => {
        checkNav();
        addListeners();
    };

    /* return the current width of the Navigation */
    const getPriorityWidth = () => {
        return priorityMenu.offsetWidth + 1;
    };

    /* return the break point of a menu item */
    const getItemBreakPoint = (item) => {
        return item.offsetLeft + item.offsetWidth;
    };

    /* test breakpoint against menu width */
    const itemBreaks = (breakPoint, menuWidth) => {
        return breakPoint > menuWidth;
    };

    /* test menuWidth against breakOut */
    const itemBreaksOut = (index, priorityWidth) => {
        if (breakout[index] < priorityWidth) {
            return true;
        }
    };

    /* move item to overflow menu */
    const addToOverflow = (item, itemBreakPoint) => {
        overflowMenu.insertBefore(item, overflowMenu.firstChild);
        breakout.unshift(itemBreakPoint);
    };

    /* remove from the overflwo menu */
    const removeFromOverflow = (breaksOut) => {
        for (let item of breaksOut) {
            breakout.shift();
            priorityMenu.appendChild(item);
        }
    };

    /* Set button visibility */
    const checkTriggerHidden = (value) => {
        if (value.toString() != overflowTrigger.getAttribute("data-hidden")) {
            overflowTrigger.setAttribute("data-hidden", value);
            overflowTrigger.setAttribute("aria-hidden", value);
            checkNav();
        }
    };

    /* Check priority and overflow */
    const checkNav = () => {
        /* check priorityMenu */
        let priorityWidth = getPriorityWidth();

        /* Iterate over the priority menu */
        let priorityIndex = menuItems.length;

        while (priorityIndex--) {
            let item = menuItems[priorityIndex];
            let itemBreakPoint = getItemBreakPoint(item);

            if (itemBreaks(itemBreakPoint, priorityWidth)) {
                addToOverflow(item, itemBreakPoint);
            }
        }

        /* iterate the overflow */
        let overflowIndex = overflowMenu.children.length;
        let breaksOut = [];

        while (overflowIndex--) {
            if (itemBreaksOut(overflowIndex, priorityWidth)) {
                breaksOut.unshift(overflowMenu.children[overflowIndex]);
            }
        }

        removeFromOverflow(breaksOut);

        /* check the trigger visibility */
        checkTriggerHidden(breakout.length == 0);
    };

    /* Add Event listeners */
    const addListeners = () => {
        window.addEventListener("resize", () => {
            //throttle
            throttle(checkNav, delay);
            //debounce
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                checkNav();
            }, delay);
        });

        overflowTrigger.addEventListener("click", () => {
            overflowMenu.setAttribute(
                "data-hidden",
                overflowMenu.getAttribute("data-hidden") === "true"
                    ? "false"
                    : "true"
            );
            overflowMenu.setAttribute(
                "aria-hidden",
                overflowMenu.getAttribute("aria-hidden") === "true"
                    ? "false"
                    : "true"
            );
        });
    };

    /* Initilaise the menu */

    initialise();
}

export default priorityNav;
