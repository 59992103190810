class Gallery {

    constructor(el) {
        this.gallery = el;
        this.galleryButtonText = this.gallery.getAttribute('data-launch-text');
        this.galleryIntroText = this.gallery.getAttribute('data-intro-text');
        this._buildLightbox();
        this._buildIntro();
    }

    _buildIntro() {
        // hide the gallery then build an intro with a cover image and a launch button
        this.gallery.style.display = 'none';

        let introContainer = document.createElement('div');
        introContainer.classList.add('sf-gallery__intro');
        this.gallery.parentElement.appendChild(introContainer);

        let _firstImage = this.gallery.querySelector('.sf-gallery__image');

        let firstImage = document.createElement('img');
        firstImage.setAttribute('src', _firstImage.getAttribute('src'));
        firstImage.setAttribute('alt', _firstImage.getAttribute('alt'));
        firstImage.classList.add('sf-gallery__intro-image');
        introContainer.appendChild(firstImage);

        let introContainerInner = document.createElement('div');
        introContainerInner.classList.add('sf-gallery__intro-inner');
        introContainer.appendChild(introContainerInner);

        let galleryIntroEl = document.createElement('h1');
        galleryIntroEl.innerText = this.galleryIntroText;
        introContainerInner.appendChild(galleryIntroEl);

        let lightboxButton = document.createElement('button');
        lightboxButton.classList.add('button');
        lightboxButton.classList.add('button--cta');
        lightboxButton.classList.add('button--cta-primary');
        lightboxButton.innerText = this.galleryButtonText;
        introContainerInner.appendChild(lightboxButton);

        lightboxButton.onclick = () => this.lightbox.luminousInstances[0].open();
    }

    _buildLightbox() {
        const triggers = this.gallery.querySelectorAll('.sf-gallery__trigger');
        const opened = () => document.documentElement.style.overflow = 'hidden';
        const closed = () => document.documentElement.style.overflow = 'auto';
        const getCaption = (trigger) => {
            let caption = trigger.parentElement.querySelector('.js-gallery-caption');
            return caption && caption.innerHTML;
        }

        const lightboxOpts = {
            caption: getCaption,
            onOpen: opened,
            onClose: closed,
            injectBaseStyles: false,
        }

        let lb = new LuminousGallery(triggers, lightboxOpts);
        this.lightbox = lb;
    }

}

export default Gallery;
