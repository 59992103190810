class Narration {
    constructor() {
        this.init();
    }

    init() {
        const narrationEl = document.getElementById("speechkit-io-iframe"),
            head = document.getElementsByTagName("head")[0],
            script = document.createElement("script");

        if (narrationEl) {
            try {
                script.src = "//cdn.jsdelivr.net/npm/@speechkit/speechkit-audio-player@latest/dist/speechkit-iframe-helper.js";
                head.appendChild(script);
            } catch (error) {
                //
            }
        };
    }
}

export default Narration;
